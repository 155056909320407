import React from 'react';
import { bool } from 'prop-types';
import AddCard from 'components/cards/add';
import AllLoyaltyCards from 'components/cards/all-loyalty-cards';

import useStyles from 'apputil/view-styles';

import { Grid } from '@material-ui/core';

const HomeLoggedInView = ({ canAdd = false, hasPass = true }) => {
  const styles = useStyles();

  return (
    <div className={styles.menucontent}>
      <Grid spacing={2}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          className={styles.grid}
        >
        {canAdd && (
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <AddCard />
          </Grid>
        )}
        {hasPass && (
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <AllLoyaltyCards />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

HomeLoggedInView.propTypes = { canAdd: bool, hasPass: bool };

export default HomeLoggedInView;
